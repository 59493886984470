<template>
    <div class="mt-3">
        <button type="button" class="btn btn-link p-0 text-decoration-none">
            <h6 class="d-inline text-primary" :title="$t('Grants access to users with relevant roles in these org units once the document is released')"></h6>
                <span style="cursor:pointer" @click="showOrgUnitRoleDlg" ><i class="bi bi-plus-lg"></i> {{$t('Distribute To Org Unit Role')}}</span>
                <button class="btn btn-sm btn-link" :disabled="isCommon" @click="shareWithCommon">
                    {{ $t("Share with Common") }}
                </button>
            </h6>
        </button>
        <ODataGrid
            :dataObject="local_dsDocOrgUnitsRoles"
            hideMultiselectColumn
            hideGridMenu
            hideSystemColumn
            ref="ref_dsDocumentOrgUnitsP">
                <OColumn field="UnitType" width="200"/>
                <OColumn field="OrgUnit" width="200" />
                <OColumn field="Role" width="200"></OColumn>
        </ODataGrid>
    </div>
    

    <OModal ref="orgUnitRoleDlg" @hide="hideOrgUnitRoleDlg">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Share With OrgUnit Roles") }}
                        </h4>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-2">
                    <ODataGrid :dataObject="local_dsDocOrgUnitsRoles" loadDataObject :hideGridMenu="true" :collapseGridMenu="true" style="min-height: 300px;">
                        <OColumn field="OrgUnit" width="250" :headerName="$t('Org Unit')" editable sortable  v-slot:editor="{modelValue: row}">
                            <OOrgUnitsLookup :bind="sel => { row.OrgUnit_ID = sel.ID; row.OrgUnit = sel.OrgUnit }"  :v-model="row.OrgUnit" >    
                                <template #target="{ target }">
                                    <input :value="row.OrgUnit" type="text" :ref="target">
                                </template>
                                
                            </OOrgUnitsLookup>

                        </OColumn>

                        <OColumn field="Role" width="250" :headerName="$t('Role')" editable sortable  v-slot:editor="{modelValue: row}">
                            <ODataLookup
                                class="form-control form-control-sm d-inline" 
                                :data-object="local_dsDocRoles" 
                                :bind="sel => {row.Role_ID = sel.ID; row.Role = sel.Name}"
                                :forceReloadOnOpen="true"
                                :v-model="row.Role">
                                <OColumn field="ID" width="100"></OColumn>
                                <OColumn field="Name" width="300"></OColumn>
                                <template #target="{target}">
                                    <input type="text" id="role" :value="row.Role" :ref="target">
                                    
                                </template>
                            </ODataLookup>
                        </OColumn>
                    </ODataGrid>
                </div>
                <div class="modal-footer justify-content-between">
                        <div>
                            <button class="btn btn-sm btn-outline-primary"  @click="shareWithCommon" :disabled="isCommon">
                                {{ $t("Share With Common") }}
                            </button>
                        </div>
                        <div>
                            <button class="btn btn-sm btn-outline-primary" @click="closeOrgUnitRoleDlg" >
                                {{ $t("Close") }}
                            </button>
                        </div>
            
                </div>
            </div>
        </div>
    </OModal>



</template>

<script setup>
import { getOrCreateDataObject } from 'o365-dataobject'
import { OOrgUnitsLookup } from 'o365-system-lookups';
import { getOrCreateProcedure } from 'o365-modules'
import { ref, computed } from 'vue';
import { useDataObjectEventListener } from 'o365-vue-utils';

const props = defineProps({
    dataObject: Object
});

const orgUnitRoleDlg = ref(null);


const dsDocOrgUnitsRolesDef = {
    id: 'local_dsDocOrgUnitsRoles',
    viewName: 'aviw_Arena_DocumentsOrgUnitsRoles',
    appId: props.dataObject.appId,
    uniqueTable:"atbv_Arena_DocumentsOrgUnitsRoles",
    allowDelete: true,
    allowUpdate:true,
    allowInsert:false,
    selectFirstRowOnLoad: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Document_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [
            {name: "OrgUnit_ID", type: "number" },
            {name: "Document_ID", type: "number" },
            {name: "OrgUnit", type: "string" },
            {name: "Role", type: "string" },
            {name: "Role_ID", type: "string" },
            {name: "UnitType", type: "string" },
        ]
}


const dsDocRoles = {
    id: 'local_dsDocRoles',
    viewName: 'stbv_System_Roles',
    appId: props.dataObject.appId,
    selectFirstRowOnLoad: true,
    fields:
        [
            {name: "ID", type: "number" },
            {name: "Name", type: "string" },
        ]
}

const dsDatabaseSetup = {
    id: 'local_dsDatabaseSetup',
    viewName: 'stbv_Database_Setup',
    appId: props.dataObject.appId,
    selectFirstRowOnLoad: true,
    fields:
        [
            {name: "PublicRole_ID", type: "number" }
        ]
}



const local_dsDocOrgUnitsRoles = getOrCreateDataObject(dsDocOrgUnitsRolesDef);
const local_dsDocRoles = getOrCreateDataObject(dsDocRoles);
const local_dsDatabaseSetup = getOrCreateDataObject(dsDatabaseSetup);
const procDocumentsOrgUnitsRolesShareWithCommon = getOrCreateProcedure({
        id: 'localProcPublishToOrgUnits',
        procedureName: 'astp_Arena_DocumentsOrgUnitsRolesShareWithCommon'
    });
local_dsDatabaseSetup.load();    

const isCommon = computed(() => local_dsDocOrgUnitsRoles.data.filter(a => a.OrgUnit_ID === props.dataObject.current.CommonSharing_ID && local_dsDatabaseSetup.current.PublicRole_ID === a.Role_ID).length > 0 || props.dataObject.current.CommonSharing_ID === null )


const showOrgUnitRoleDlg = (sel) => {
    orgUnitRoleDlg.value.show();
    local_dsDocOrgUnitsRoles.allowInsert = true
}

const hideOrgUnitRoleDlg = (sel) => {
    local_dsDocOrgUnitsRoles.allowInsert = false;
}

const closeOrgUnitRoleDlg = (sel) => {
    local_dsDocOrgUnitsRoles.allowInsert = false;
    orgUnitRoleDlg.value.hide();
}

const shareWithCommon = (sel) => {
    procDocumentsOrgUnitsRolesShareWithCommon.execute({
        Document_ID : props.dataObject.current.ID
    }).then((response, err) => {
        local_dsDocOrgUnitsRoles.load();
    }).finally(() => {
        
    }); 
}

useDataObjectEventListener(local_dsDocOrgUnitsRoles, 'AfterSave', () => {
    local_dsDocOrgUnitsRoles.load();
});
</script>